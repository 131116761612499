import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useAuthRoutes } from './routes'
import rootStore from './store'
import MainLayout from '@/ui/MainLayout'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'

import ClarityTracker from '@/features/analytics/clarity'
import { KitDigitalPaymentNotice } from '@/components/KitDigitalToast'
import { observer } from 'mobx-react'
import { useFlushTrackingEventsBeforeUnload } from '@/features/tracking/hooks'

import { ToastContainer } from 'react-toastify'
import LinearProgress from '@mui/material/LinearProgress'
import 'react-toastify/dist/ReactToastify.css'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const App = observer(() => {
  const { auth } = rootStore
  const [checkedAuth, setcheckedAuth] = useState(false)

  useFlushTrackingEventsBeforeUnload()

  useEffect(() => {
    auth.checkAuth().then(() => {
      setcheckedAuth(true)
    })
  }, [])

  const authRoutes = useAuthRoutes()

  if (!checkedAuth) {
    return (
      <div className="top-loading-bar">
        <LinearProgress />
      </div>
    )
  }

  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/auth/signup" lazy={() => import('./pages/SignupPage')} />
        <Route
          path="/auth/quick-signup"
          lazy={() => import('./pages/auth/QuickSignupPage')}
        />
        <Route path="/auth/login" lazy={() => import('./pages/SigninPage')} />
        <Route
          path="/auth/verify/:token"
          lazy={() => import('./pages/auth/VerificationPage')}
        />

        <Route
          path="/accept-invite"
          lazy={() => import('./pages/auth/AcceptInvitePage')}
        />

        <Route
          path="/forgot-password"
          lazy={() => import('./pages/auth/ForgotPasswordPage')}
        />
        <Route
          path="/reset-password"
          lazy={() => import('./pages/auth/ResetPasswordPage')}
        />

        <Route path="/landing" lazy={() => import('./pages/LandingPage')} />
        <Route path="/" lazy={() => import('./pages/LandingPage')} />
        {/* <Route
          path="/"
          element={<ExternalRedirect url={'https://www.aiconsortivm.com'} />}
        /> */}

        {authRoutes ? (
          <Route element={<MainLayout />}>{authRoutes}</Route>
        ) : (
          <Route path="*" lazy={() => import('./pages/SigninPage')} />
        )}

        <Route
          path="/privacy-policy"
          lazy={() => import('./pages/PrivacyPolicyPage')}
        />
      </Route>,
    ),
  )

  return (
    <>
      <ToastContainer hideProgressBar />
      <KitDigitalPaymentNotice />
      <ClarityTracker />
      <RouterProvider router={router} />
    </>
  )
})

export default App
